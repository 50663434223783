import slugifyBase from 'slugify';

export interface GitLabParsedUrl {
  fullPath: string;
}

export interface BitbucketParsedUrl {
  fullPath: string;
}

export interface GitHubParsedUrl {
  owner: string;
  repoName: string;
}

export function isRemoteUrl(url: string): boolean {
  if (typeof url !== 'string') return false;
  try {
    // Throws here if malformed URL
    const pathUrl = new URL(url);
    // Be VERY specific with protocols here, since URL() does no protocol validation
    return (
      pathUrl.protocol.startsWith('http:') ||
      pathUrl.protocol.startsWith('https:')
    );
  } catch (e) {
    // Starting a url with '//' is invalid to URL(), but we support it
    return url.startsWith('//');
  }
}

/**
 * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 */
export const isDataUrl = (dataString: string): boolean =>
  dataString.startsWith('data:');

export const slugify = (
  opt:
    | string
    | {
        string: string;
        /**
         * Removes all special characters. Necessary when used to create JS targets
         * that are invalid when special characters are present.
         */
        strict?: boolean;
        lowerCase?: boolean;
      },
): string => {
  const {
    string,
    strict = true,
    lowerCase = true,
  } = typeof opt === 'string' ? { string: opt } : opt;
  return slugifyBase(string, {
    lower: lowerCase,
    trim: true,
    strict,
  });
};

/**
 * When dealing with nav paths, we need to find the entity type of the link (first
 * part, e.g. 'page' or 'pattern') and the entity id (second part, e.g. 'home').
 */
export const extractPathParts = (path: string): string[] | null => {
  const regex = /^\/([^/]+)\/([^/]+)/;
  const match = path.match(regex);

  if (!match) return null;

  const [_, entityType, entityId] = match;
  return [entityType, entityId];
};

export function createFigmaUrl({
  fileId,
  nodeId,
  version,
}: {
  fileId: string;
  nodeId?: string;
  version?: string | number;
}) {
  if (!fileId || fileId.trim().length === 0) {
    throw new Error(`Invalid Figma URL, missing file id: ${fileId}`);
  }
  const url = new URL(`https://www.figma.com/file/${fileId}`);
  if (nodeId) {
    url.searchParams.set('node-id', nodeId);
  }
  if (version) {
    url.searchParams.set('version-id', version.toString());
  }
  return url.toString();
}

export function parseFigmaUrl(figmaUrl: string): {
  type: 'proto' | 'file' | 'design' | 'board';
  fileId: string;
  nodeId?: string;
  version?: string;
} {
  const url = new URL(figmaUrl);
  // tried to update to use the regex from figma, but it ended up being too strict
  // noting here in case we revisit
  // /https:\/\/[\w\.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;
  if (
    (url.hostname !== 'www.figma.com' && url.hostname !== 'figma.com') ||
    !isRemoteUrl(figmaUrl) // Ensure it's a http/https URL.
  ) {
    throw new Error(`Please provide a valid Figma URL, got ${figmaUrl}`);
  }
  const nodeId = url.searchParams.get('node-id');
  const version = url.searchParams.get('version-id');
  const [, type, fileId] = url.pathname.split('/');
  switch (type) {
    case 'proto':
    case 'file':
    case 'board':
    case 'design': {
      if (!fileId) {
        throw new Error(`Invalid Figma URL, missing file id: ${figmaUrl}`);
      }
      return {
        type,
        fileId,
        nodeId,
        version,
      };
    }
    default:
      throw new Error(`Please provide a valid Figma URL, got ${figmaUrl}`);
  }
}
